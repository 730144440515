import React, { useState } from 'react'
import ConfirmationStep from './ConfirmationStep'
import CreateKeyStep from './CreateKeyStep'

const NewKeyForm = (props) => {
  const [step, setStep] = useState('confirmation')

  return (
    <>
      {(step === 'confirmation') && <ConfirmationStep setStep={setStep} />}
      {(step === 'createKey') && <CreateKeyStep {...props} />}

    </>
  )
}

export default NewKeyForm
