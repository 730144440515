import React, { useState } from 'react'

const ConfirmationStep = ({ setStep }) => {
  const [cancelled, setCancelled] = useState(false)

  const closeWindow = () => {
    window.close()
    document.location.href = 'about:blank'
  }

  const DefaultView = () => {
    return (
      <>
        <h2 className='my-8 text-3xl'> ATTENTION </h2>
        <p className='mb-8 text-xl'>
          Before you proceed in creating the Mobile Key, did you:
        </p>

        <div className='py-4'>
          <dt className='py-2 text-xl font-bold'>Receive a Mobile Key Request in Messenger from the guest?</dt>
          <dd>If no select <span className='font-bold'> Cancel </span></dd>
        </div>

        <div className='py-4'>
          <dt className='py-2 text-xl font-bold'>Did you create a Physical/Hard Key first?</dt>
          <dd>If no select <span className='font-bold'> Cancel </span></dd>
        </div>

        <p className='mt-8'>
          If those two steps have been completed prior, please proceed with creating Mobile Key by selecting Continue.
        </p>

        <div className='flex justify-evenly'>
          <a onClick={() => setStep('createKey')} className='px-5 py-3 mt-8 text-sm font-bold text-white uppercase bg-black rounded-full cursor-pointer'>
            CONTINUE
          </a>

          <a onClick={() => setCancelled(true)} className='px-5 py-3 mt-8 text-sm font-bold text-white uppercase bg-red-800 rounded-full cursor-pointer'>
            Cancel
          </a>
        </div>
      </>
    )
  }

  const CancelConfirmationView = () => {
    return (
      <div className='text-xl font-bold'>
        <p className='my-8 text-xl font-bold'> No Mobile key created </p>
        <p className='my-8'>
          You can close this window and restart the process after you receive the
          <span className='font-bold'> mobile key request </span>
          and have created the
          <span className='font-bold'>hard key</span>.
        </p>
      </div>
    )
  }

  return (
    <>
      <div className='mt-16 text-center' id='container'>
        {cancelled && CancelConfirmationView()}
        {!cancelled && DefaultView()}
      </div>
    </>
  )
}

export default ConfirmationStep
