import React, { useState } from 'react'

const CreateKeyStep = ({ commonAreas, hotelId, mobileKey, timeZone }) => {
  const [checked, setChecked] = useState([])
  const [json, setJson] = useState()
  const [loading, setLoading] = useState(false)
  const [ok, setOk] = useState()

  const createMobileKey = () => {
    setLoading(true)

    fetch('/api/keys', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        common_area_ids: checked,
        hotel_id: hotelId,
        key: mobileKey,
        time_zone: timeZone
      })
    }).then(response => {
      setLoading(false)
      setOk(response.ok)
      return response.json()
    }).then(data => setJson(data))
  }

  const handleChange = (event) => {
    const value = parseInt(event.target.value)
    if (event.target.checked) {
      setChecked([...checked, value])
    } else {
      setChecked(checked.filter(x => x !== value))
    }
  }

  return (
    <>
      <dl>
        <div className='flex py-1'>
          <dt className='flex-initial font-bold w-44'>Golden ID</dt>
          <dd className='flex-1'>{mobileKey?.gid}</dd>
        </div>

        <div className='flex py-1'>
          <dt className='flex-initial font-bold w-44'>Guest Name</dt>
          <dd className='flex-1'>{[mobileKey?.fn, mobileKey?.ln].join(' ')}</dd>
        </div>

        <div className='flex py-1'>
          <dt className='flex-initial font-bold w-44'>Mobile Number</dt>
          <dd className='flex-1'>{mobileKey?.mn}</dd>
        </div>

        <div className='flex py-1'>
          <dt className='flex-initial font-bold w-44'>Arrival Date</dt>
          <dd className='flex-1'>{mobileKey?.ad}</dd>
        </div>

        <div className='flex py-1'>
          <dt className='flex-initial font-bold w-44'>Departure Date</dt>
          <dd className='flex-1'>{mobileKey?.dd}</dd>
        </div>

        <div className='flex py-1'>
          <dt className='flex-initial font-bold w-44'>Checkout Time</dt>
          <dd className='flex-1'>{mobileKey?.cot}</dd>
        </div>

        <div className='flex py-1'>
          <dt className='flex-initial font-bold w-44'>Room Number</dt>
          <dd className='flex-1'>{mobileKey?.rn}</dd>
        </div>

        <div className='flex py-1'>
          <dt className='flex-initial font-bold w-44'>PMS Confirmation #</dt>
          <dd className='flex-1'>{mobileKey?.pms}</dd>
        </div>

        <div className='flex py-1'>
          <dt className='flex-initial font-bold w-44'>ORS Confirmation #</dt>
          <dd className='flex-1'>{mobileKey?.ors}</dd>
        </div>
      </dl>

      <h3 className='mt-4 text-xl font-bold'>Common Areas</h3>

      <div className='mt-2'>
        {commonAreas.length === 0 && <>There are no common areas for this hotel.</>}
        {commonAreas.map((commonArea, index) => (
          <div key={index}>
            <input type='checkbox' value={commonArea.id} onChange={handleChange} />
            <span className='ml-2'>{commonArea.title}</span>
          </div>
        ))}
      </div>

      <button
        className='px-5 py-3 mt-8 text-sm font-bold text-white uppercase bg-black rounded-full'
        onClick={createMobileKey}
        disabled={loading}
      >
        {loading ? 'Please wait...' : 'Create Key'}
      </button>

      {ok === true && <div className='p-2 mt-4 text-green-900 bg-green-500'>Mobile Key for Room #{mobileKey?.rn} has been successfully created.</div>}
      {ok === false && <div className='p-2 mt-4 text-red-900 bg-red-500'>Error creating Mobile Key for Room #{mobileKey.rn}: {json?.error} Please create the key manually.</div>}
    </>
  )
}

export default CreateKeyStep
